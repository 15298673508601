$(document).on('mouseenter', '.notification-item.new', function(){
    let elem = $(this)
    let id = elem.data('id')
    let btn = $('.toggle-notification')
    let count = parseInt(btn.attr('data-count')) - 1

    btn.attr('data-count', count)
    elem.removeClass('new')

    postAjax('init_see_notif', id)
})

$(document).on('click', '.read-all:not(.disabled)', function(){
    let elem = $(this)
    let btn = $('.toggle-notification')
    let items = $('.notification-item')

    elem.addClass('disabled')
    btn.attr('data-count', 0)
    items.removeClass('new')

    postAjax('init_see_all_notif')
})
$(document).on('click', '.balance-link', function(e){
    let form = $('.balance-form')
    let input = form.children('input')

    form.stop().slideDown(300)
    input.focus()
    e.preventDefault()
})

$(document).on('submit', '.balance-form', function(e){
    let elem = $(this)
    let input = elem.find('input')
    let val = parseInt(input[0].mask.unmaskedValue) || 0

    if(val < 1000) {
        addNotif({
            title: 'Ошибка',
            text: 'Минимальная сумма пополнения 1000 тг.',
            icon: 'close',
            color: 'red',
            timeout: 4000
        })
    } else {
        input.disable()

        requestAjax('ajax_init_add_payment', {
            sum: val,
            type: 'balance'
        }, payment_id => {
            window.location.href = '/dashboard/payments/payment/?payment_id=' + payment_id
        })
    }
    e.preventDefault()
})
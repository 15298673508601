function headerScroll() {
    const scrolled = Math.round($(window).scrollTop())
    const header = $('header.site-header')

    if(scrolled) {
        header.addClass('scroll')
    }else{
        header.removeClass('scroll')
    }

}

headerScroll()

$(window).scroll(headerScroll)

$(document).on('mouseenter', 'header .user-block', function(){
    let elem = $(this)
    let dropdown = elem.find('.dropdown-menu')

    dropdown.stop().fadeIn(200)
})

$(document).on('mouseleave', 'header .user-block', function(){
    let elem = $(this)
    let dropdown = elem.find('.dropdown-menu')

    dropdown.stop().fadeOut(200)
})

$(document).on('click', '.toggle-notification', function(){
    let elem = $(this)
    let dropdown = elem.next()

    dropdown.stop().fadeToggle(200)
})

$(document).mouseup( function(e){
    let notifications = $('.notification-wrapper')
    let dropdown = notifications.find('.notifications-dropdown')

    if ( !notifications.is(e.target) && notifications.has(e.target).length === 0 ) {
        dropdown.stop().fadeOut(200)
    }
})

$(document).ready(function () {
    let menu = $('.mobile-menu')

    menu.addClass('loaded')
})

$(document).on('click', '.toggle-menu', function(){
    let menu = $('.mobile-menu')

    menu.toggleClass('show')
})

$(document).on('click', '.close-menu', function(){
    let menu = $('.mobile-menu')

    menu.removeClass('show')
})

$(document).mouseup( function(e){
    let menu_group = $('.mobile-menu, .toggle-menu')

    if ( !menu_group.is(e.target) && menu_group.has(e.target).length === 0 ) {
        menu_group.removeClass('show')
    }
})
let company_form = {}
window.company_form = company_form

company_form.add_form = $('.add-company-form')
company_form.edit_form = $('.edit-company')

if(company_form.add_form.length) {
    company_form.steps_wrapper = $('.add-company-steps')
    company_form.steps = company_form.steps_wrapper.find('.step')
    company_form.sidebar = $('.sidebar')
    company_form.prev_btn = $('.step-prev')
    company_form.next_btn = $('.step-next')
    company_form.data = {}
    company_form.wrapper = company_form.steps_wrapper.parents('.content')

    Object.defineProperty(company_form, 'step', {
        set: (data) => {
            this.current_step = data
            
            let list = []
            let label = company_form.sidebar.data('label')
            
            company_form.steps.each(function(){
                let elem = $(this)
                let index = elem.index()
                let name = elem.data('name')
                let elem_classes = ['icon-check']

                if(index == data) {
                    elem_classes.push('current')
                }

                list.push(`<li class="${elem_classes.join(' ')}">${name}</li>`)

            })

            let current_number = data + 1
            let length = company_form.steps.length

            company_form.sidebar.html(`
                <div class="article-mc">
                    <div class="label">${label}</div>
                    <div class="add-company-progress"><span>${current_number > 9 ? current_number : '0' + current_number}</span>/${length > 9 ? length : '0' + length}</div>
                </div>

                <ul class="steps">${list.join(' ')}</ul>
            `)

            company_form.steps.removeClass('active')
            company_form.steps.eq(data).addClass('active')

            company_form.prev_btn.disable()

            if(data) {
                company_form.prev_btn.enable()
            }

            localStorage.setItem('current_step', data)

            if(window.ymaps_data) {
                window.ymaps_data.map.container.fitToViewport()
            }

            return data
        },
        get: () => {
            return this.current_step
        }
    })

    company_form.prev = () => {
        let index = Math.max(company_form.step - 1, 0)

        company_form.step = index
    }

    company_form.next = () => {
        let step = company_form.steps.eq(company_form.step)
        let slug = step.data('slug')
        let errors = 0

        if(slug == 'general') {
            let inputs = step.find('input:not(:disabled):not([name="company_microdistrict"])')

            inputs.each(function(){
                let elem = $(this)

                errors += elem.check()
            })

        } else if (slug == 'location') {
            if(!window.ymaps_data.coords) {
                errors++

                addNotif({
                    title: 'Ошибка',
                    text: 'Выберите локацию',
                    icon: 'error',
                    color: 'red',
                    timeout: 4000,
                })
            }
        } else if (slug == 'gallery') {
            let input = $('#company_gallery')
            if(!input[0].files_list.length) {
                errors++

                addNotif({
                    title: 'Ошибка',
                    text: 'Выберите изображения',
                    icon: 'error',
                    color: 'red',
                    timeout: 4000,
                })
            }
        } else if (slug == 'description') {
            let fields = step.find('textarea')

            fields.each(function(){
                let elem = $(this)
                errors += elem.check()
            })

        } else if (slug == 'contacts') {
            let phone_input = $('#company_phone')
            let fields_to_check = [phone_input]

            for(let field of fields_to_check) {
                errors += field.check()
            }

        }

        if(!errors) {
            let length = company_form.steps.length
            if(company_form.step + 1 == length) {
                let register_form = $('.step[data-slug="register"]')

                if(register_form.length) {
                    register(send_company)
                } else {
                    send_company()
                }

                function send_company() {
                    company_form.update_data()

                    company_form.wrapper.start_loading()
                    company_form.wrapper.find('input, textarea, button').disable()
                    
                    let filtred_gallery = []
                    let gallery = company_form.data.gallery
    
                    function process_gallery_file(file) {
                        let reader = new FileReader()
                        reader.onloadend = () => {
                            let type = file.type
                            let name = file.name
                            let size = file.size
                            let base64 = reader.result
    
                            filtred_gallery.push({type, name, size, base64})
    
                            gallery.shift()
    
                            if(gallery.length) {
                                process_gallery_file(gallery[0])
                            } else {
                                company_form.data.gallery = filtred_gallery
    
                                // ОТПРАВКА
                                requestAjax('add_company', company_form.data, (res) => {
                                    localStorage.removeItem('company_form');
                                    localStorage.removeItem('current_step');
    
                                    if(!window.debug) {
                                        window.location.href = '/dashboard/'
                                    }
                                })
                            }
    
                        }
                        reader.readAsDataURL(file)
                    }
    
                    process_gallery_file(gallery[0])
                }
            } else {
                let index = Math.min(company_form.step + 1, company_form.steps.length - 1)
        
                company_form.step = index
                company_form.update_data()
            }
        }
    }

    company_form.update_data = () => {
        company_form.data = {}

        company_form.steps.each(function(){
            let step = $(this)
            let slug = step.data('slug')

            if(slug == 'general') {
                let inputs = step.find('input:not(:disabled)')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    company_form.data[name] = elem.getValue()
                })
    
            } else if (slug == 'filters') {
                let filters = step.find('.filter-group')

                filters.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let values = []
                    let inputs = elem.find('input')

                    inputs.each(function(){
                        let item = $(this)
                        let value = item.data('slug')

                        if(item.prop('checked')) {
                            values.push(value)
                        }
                    })

                    company_form.data[name] = values
                })
            } else if (slug == 'location') {
                company_form.data.address = window.ymaps_data.address 
                company_form.data.coords = window.ymaps_data.coords 
            } else if (slug == 'gallery') {
                let input = $('#company_gallery')
                company_form.data.gallery = input[0].files_list
            } else if(slug == 'description') {
                let inputs = step.find('textarea')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    company_form.data[name] = elem.getValue()
                })
            } else if(slug == 'contacts') {
                let inputs = step.find('input')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    company_form.data[name] = elem.getValue()
                })
            }
        })

    }

    company_form.prev_btn.click(company_form.prev)
    company_form.next_btn.click(company_form.next)

    let active = company_form.steps_wrapper.find('.step.active').index()
    let saved_active = localStorage.getItem('current_step')

    if(saved_active) {
        let max = $('[data-slug="gallery"]').index()
        saved_active = Math.min(saved_active, max)
        active = saved_active
    }

    company_form.step = Math.max(active, 0)

    company_form.steps.find('input, textarea').on('change location_selected selected', function(){
        let storage = JSON.parse(localStorage.getItem('company_form')) || {}
        let elem = $(this)
        let value = elem.getValue()
        let type = elem.data('type') || 'text'
        let id = elem.attr('id')

        if( type == 'checkbox' ) {
            value = elem.prop('checked') ? 1 : 0
        }

        storage[id] = {id, type, value}
        localStorage.setItem('company_form', JSON.stringify(storage))
    })

    $(document).ready(function () {
        let storage = JSON.parse(localStorage.getItem('company_form')) || ''
        
        if(storage) {
            for(let data of Object.values(storage)) {
                let {id, type, value} = data
                let input = $('#' + id)
    
                if(input.length) {
    
                    if(type == 'select') {
                        input[0].selected = value
                    } else if (type == 'text') {
                        input.val(value)
                    } else if (type == 'checkbox') {
                        input.prop('checked', value)
                    } else if (type == 'map') {
                        window.ymaps_data.onload.push(() => {
                            window.ymaps_data.search(value)
                        })
                    } else if (type == 'phone') {
                        input[0].mask.unmaskedValue = value
                    }
    
                }
            }
        }

        update_categories()
        update_locations()
        company_form.update_data()
    })

} else if(company_form.edit_form.length) {
    company_form.steps_wrapper = $('.edit-company')
    company_form.steps = company_form.steps_wrapper.find('.step')
    company_form.data = {}
    company_form.wrapper = company_form.steps_wrapper.parents('.dashboard')
    company_form.submit = company_form.edit_form.find('#save_company')

    company_form.update_data = () => {
        company_form.data = {}

        company_form.steps.each(function(){
            let step = $(this)
            let slug = step.data('slug')

            if(slug == 'general') {
                let inputs = step.find('input:not(:disabled)')
                
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    company_form.data[name] = elem.getValue()
                })
    
            } else if (slug == 'filters') {
                let filters = step.find('.filter-group')

                filters.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let values = []
                    let inputs = elem.find('input')

                    inputs.each(function(){
                        let item = $(this)
                        let value = item.data('slug')

                        if(item.prop('checked')) {
                            values.push(value)
                        }
                    })

                    company_form.data[name] = values
                })
            } else if (slug == 'location') {
                company_form.data.address = window.ymaps_data.address 
                company_form.data.coords = window.ymaps_data.coords 
            } else if (slug == 'gallery') {
                let input = $('#company_gallery')
                company_form.data.gallery = input[0].files_list
            } else if(slug == 'description') {
                let inputs = step.find('textarea')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    company_form.data[name] = elem.getValue()
                })
            } else if(slug == 'contacts') {
                let inputs = step.find('input')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    company_form.data[name] = elem.getValue()
                })
            } else if(slug == 'other') {
                let schedule = step.find('.schedule-days')
                let days = schedule.find('.schedule-day')
                let schedule_data = {}

                let social = step.find('.social-item')
                let social_data = {}

                days.each(function(){
                    let elem = $(this)
                    let day = elem.data('day')
                    let from = elem.find('.from').getValue()
                    let to = elem.find('.to').getValue()
                    let output = !elem.find('.output').prop('checked') ? 1 : 0

                    schedule_data[day] = {from, to, output}
                })

                social.each(function(){
                    let elem = $(this)
                    let type = elem.data('social')
                    let input = elem.find('input')
                    let value = input.getValue()

                    social_data[type] = value
                })

                company_form.data['social'] = social_data
                company_form.data['schedule'] = schedule_data
            }
        })

    }

    let active = company_form.steps_wrapper.find('.step.active').index()
    let saved_active = localStorage.getItem('current_step')

    if(saved_active) {
        let max = $('[data-slug="gallery"]').index()
        saved_active = Math.min(saved_active, max)
        active = saved_active
    }

    company_form.step = Math.max(active, 0)

    company_form.steps.find('input, textarea').on('change location_selected selected', function(){
        let storage = JSON.parse(localStorage.getItem('company_form')) || {}
        let elem = $(this)
        let value = elem.getValue()
        let type = elem.data('type') || 'text'
        let id = elem.attr('id')

        if( type == 'checkbox' ) {
            value = elem.prop('checked') ? 1 : 0
        }

        storage[id] = {id, type, value}
        localStorage.setItem('company_form', JSON.stringify(storage))
    })

    company_form.submit.on('click', () => {
        let errors = 0

        company_form.steps.each(function(){
            let step = $(this)
            let slug = step.data('slug')

            if(slug == 'general') {
                let inputs = step.find('input:not(:disabled):not([name="company_microdistrict"])')
    
                inputs.each(function(){
                    let elem = $(this)
    
                    errors += elem.check()
                })
    
            } else if (slug == 'location') {
                if(!window.ymaps_data.coords) {
                    errors++
    
                    addNotif({
                        title: 'Ошибка',
                        text: 'Выберите локацию',
                        icon: 'error',
                        color: 'red',
                        timeout: 4000,
                    })
                }
            } else if (slug == 'gallery') {
                let input = $('#company_gallery')
                if(!input[0].files_list.length) {
                    errors++
    
                    addNotif({
                        title: 'Ошибка',
                        text: 'Выберите изображения',
                        icon: 'error',
                        color: 'red',
                        timeout: 4000,
                    })
                }
            } else if (slug == 'description') {
                let fields = step.find('textarea')
    
                fields.each(function(){
                    let elem = $(this)
                    errors += elem.check()
                })
    
            } else if (slug == 'contacts') {
                let phone_input = $('#company_phone')
                let fields_to_check = [phone_input]
    
                for(let field of fields_to_check) {
                    errors += field.check()
                }
    
            }

        })

        if(!errors) {
            company_form.update_data()
            company_form.wrapper.find('input, textarea, button').disable()
            
            let filtred_gallery = []
            let gallery = company_form.data.gallery

            function append_gallery_file(file) {
                let type = file.type
                let name = file.name
                let size = file.size
                let base64 = file.base64

                filtred_gallery.push({type, name, size, base64})

                gallery.shift()

                if(gallery.length) {
                    process_gallery_file(gallery[0])
                } else {
                    company_form.data.gallery = filtred_gallery

                    // ОТПРАВКА
                    requestAjax('edit_company', {
                        id: window.post_id,
                        data: company_form.data,
                    }, (res) => {

                        if(!window.debug) {
                            addNotif({
                                title: 'Сохранено',
                                text: 'Изменения были успешно сохранены',
                                icon: 'success',
                                color: 'green',
                                timeout: 4000,
                            })

                            setTimeout(() => {
                                window.location.reload()
                            }, 4000)
                        }
                    })
                }
            }

            function process_gallery_file(file) {
                if(file.base64) {
                    append_gallery_file(file)  
                } else {
                    let reader = new FileReader()
                    reader.onloadend = () => {
                        file.base64 = reader.result

                        append_gallery_file(file)    
                    }
                    reader.readAsDataURL(file)
                }
            }

            process_gallery_file(gallery[0])
        }
    })

    $(document).ready(function () {
        update_categories()
        update_locations()
        company_form.update_data()
    })
}

function update_categories () {
    let category_input = $('#company_category')
    let category_dropdown = category_input.siblings('.dropdown-menu').find('.menu-content')
    let subcategory_input = $('#company_subcategory')
    let subcategory_dropdown = subcategory_input.siblings('.dropdown-menu').find('.menu-content')

    let category_items = []
    let subcategory_items = []

    category_dropdown.html('')
    subcategory_dropdown.html('')
    subcategory_input.disable()

    let raw_categories = Object.values(window.categories.categories)

    if(raw_categories.length) {
        for(let category of raw_categories) {
            category_items.push(`<div class="item" data-value="${category.id}">${category.name}</div>`)
        }

        category_dropdown.html(category_items.join(''))
        category_input[0].refresh(true)
        
        let selected_category = category_input[0].selected

        if(window.categories.category_subcategory[selected_category]) {
            let raw_subcategories = window.categories.category_subcategory[selected_category]

            for(let subcategory of raw_subcategories) {
                subcategory = window.categories.subcategories[subcategory]
                subcategory_items.push(`<div class="item" data-value="${subcategory.id}">${subcategory.name}</div>`)
            }

            subcategory_input.enable()
            subcategory_dropdown.html(subcategory_items.join(''))

            subcategory_input[0].refresh(true)
        } else {
            subcategory_input[0].selected = ''
        }

    }
}

function update_locations () {
    let search_request = []
    let region_input = $('#company_region, #nanny_region')
    let region_dropdown = region_input.siblings('.dropdown-menu').find('.menu-content')

    let district_input = $('#company_district, #nanny_district')
    let district_dropdown = district_input.siblings('.dropdown-menu').find('.menu-content')

    let microdistrict_input = $('#company_microdistrict, #nanny_microdistrict')
    let microdistrict_dropdown = microdistrict_input.siblings('.dropdown-menu').find('.menu-content')

    let region_items = []
    let district_items = []
    let microdistrict_items = []

    region_dropdown.html('')

    district_dropdown.html('')
    district_input.disable()

    microdistrict_dropdown.html('')

    if(microdistrict_input.length) {
        microdistrict_input.disable()
    }

    let raw_regions = Object.values(window.locations.regions)

    if(raw_regions.length) {
        let selected_region = region_input[0].selected
        for(let region of raw_regions) {
            region_items.push(`<div class="item" data-value="${region.id}">${region.name}</div>`)

            if(region.id == selected_region) {
                search_request.push(region.name)
            }
        }

        region_dropdown.html(region_items.join(''))
        region_input[0].refresh(true)

        if(window.locations.region_districts[selected_region]) {
            let selected_district = district_input[0].selected
            let raw_districts = window.locations.region_districts[selected_region]

            for(let district of raw_districts) {
                district = window.locations.districts[district]
                district_items.push(`<div class="item" data-value="${district.id}">${district.name}</div>`)

                if(district.id == selected_district) {
                    search_request.push(district.name)
                }

            }

            district_input.enable()
            district_dropdown.html(district_items.join(''))
            district_input[0].refresh(true)

            if(microdistrict_input.length) {
                if(window.locations.disctrict_microdistricts[selected_district]) {
                    let selected_microdistrict = microdistrict_input[0].selected
                    let raw_microdistricts = window.locations.disctrict_microdistricts[selected_district]
    
                    for(let microdistrict of raw_microdistricts) {
                        microdistrict = window.locations.microdistricts[microdistrict]
                        microdistrict_items.push(`<div class="item" data-value="${microdistrict.id}">${microdistrict.name}</div>`)
    
                        if(microdistrict.id == selected_microdistrict) {
                            search_request.push(microdistrict.name)
                        }
    
                    }
        
                    microdistrict_input.enable()
                    microdistrict_dropdown.html(microdistrict_items.join(''))
                    microdistrict_input[0].refresh(true)
                } else {
                    microdistrict_input[0].selected = ''
                }
            }

        } else {
            district_input[0].selected = ''
        }
    }

    if(search_request.length && window.ymaps_data) {
        function update_map() {
            let zoom = search_request.length * 3 + 8

            window.ymaps_data.search(search_request.join(', '), false, zoom)
        }

        if(window.ymaps_data.ready) {
            update_map()
        } else {
            window.ymaps_data.onload.push(() => {
                update_map()
            })
        }
    }

}
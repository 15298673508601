let section = $('section.map')

if(section.length) {

    $(document).ready(function () {
        update_locations()        
    })

    if (typeof ymaps !== 'undefined' && $('#map').length) {
        let ymaps_data = {
            suggest: $('#map_search'),
            suggest_id: 'map_search',
            map_elem: $('#map'),
            map_id: 'map',
            ready: false,
            onload: [],
            clusters: []
        }
    
        window.ymaps_data = ymaps_data
    
        ymaps.ready(() => {
            ymaps_data.map = new ymaps.Map(ymaps_data.map_id, {
                center: [43.238293, 76.945465],
                zoom: 12,
                type: 'yandex#map',
                controls: ['geolocationControl', 'zoomControl']
            }, {
                searchControlProvider: 'yandex#search',
                yandexMapDisablePoiInteractivity: true
            },{suppressMapOpenBlock: true})

            ymaps_data.ruler = ymaps_data.map.behaviors.get('ruler')
            ymaps_data.clusterer = new ymaps.Clusterer({
                preset: 'islands#invertedRedClusterIcons',
                groupByCoordinates: false,
                clusterDisableClickZoom: true,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,
                clusterOpenBalloonOnClick: false
            })

            // function process_address(key, coords) {
            //     let address_line = []
    
            //     ymaps.geocode(coords, {kind: 'district'}).then(function (res) {
            //         let districtObject = res.geoObjects.get(0)
            //         let district_components = districtObject.properties._data.metaDataProperty.GeocoderMetaData.Address.Components
            //         let filtred_components = {}
            //         let old_districts = []
    
            //         for(let component of district_components) {
            //             let kind = component.kind
            //             let name = component.name

            //             if(kind == 'province') {
            //                 address_line.push(name)
            //             }

            //             if(kind == 'district') {
            //                 address_line.push(name)
            //                 old_districts.push(name)
            //             }
            //             filtred_components[component.kind] = component.name
            //         }
    
            //         ymaps.geocode(coords, {kind: 'house'}).then(function (res) {
            //             let firstGeoObject = res.geoObjects.get(0)
            //             let components = firstGeoObject.properties._data.metaDataProperty.GeocoderMetaData.Address.Components
            //             let filtred_components = {}
        
            //             for(let component of components) {
            //                 let kind = component.kind
            //                 let name = component.name

            //                 if(kind == 'house' || kind == 'street') {
            //                     address_line.push(name)
            //                 }
    
            //                 if(kind == 'district' && old_districts.indexOf(name) == -1) {
            //                     address_line.push(name)
            //                     old_districts.push(name)
            //                 }

            //                 filtred_components[component.kind] = component.name
            //             }
    
            //             let address = address_line.join(', ')

            //             address = address.replaceAll('микрорайон', 'мкр.')
            //             address = address.replaceAll('район', 'р-н')
            //             address = address.replaceAll('улица', 'ул.')
            //             address = address.replaceAll('жилой комплекс', 'жк.')
            //             address = address.replaceAll('проспект', 'пр.')
            //             address = address.replaceAll('блок', ' блок ')

            //             console.log(address);
            //         })
            //     })
            // }
            
            // for(let key in window.addresses) {
            //     process_address(key, window.addresses[key])
            // }
            
            for(let key in window.addresses) {
                let coords = window.addresses[key]
                let placemark = new ymaps.Placemark(coords, {
                    iconCaption: '',
                    id: key,
                    coords: coords,
                }, {
                    preset: 'islands#redCircleDotIcon',
                    draggable: false,
                    iconCaptionMaxWidth: '1000',
                    hasBalloon: false,
                    id: key
                })

                ymaps_data.clusters.push(placemark)
            }

            ymaps_data.clusterer.add(ymaps_data.clusters)
            ymaps_data.clusterer.events.add('click', (e) => {
                let target = e.get('target')
                let zoom_coords

                if(target.getGeoObjects) {
                    let objects = target.getGeoObjects()
                    let ids = []
    
                    for(let object of objects) {
                        let {id, coords} = object.properties._data
                        
                        ids.push(id)
                        zoom_coords = coords
                    }
                    
                    open_companies(ids)
                } else {
                    let {id, coords} = target.properties._data
                    zoom_coords = coords

                    open_company(id)
                }

                ymaps_data.map.setCenter(zoom_coords, 17, {
                    duration: 500
                })

            })
            ymaps_data.map.geoObjects.add(ymaps_data.clusterer)

            ymaps_data.ready = true
    
            suggestView = new ymaps.SuggestView(ymaps_data.suggest_id, {
                provider: {
                    suggest : function(request) {
                        request = 'Казахстан, ' + request
                        
                        let suggest = new ymaps.suggest(request)
                        suggest.then(function (items){
    
                            $.each(items, function(index, key){                                  
                                key.value = key.value.replace(/(.*?), /, '')
                                key.displayName = key.displayName.split(', ').slice(0, -1).join(', ')                                                              
                            })
                            
                        })             
                    
                        return suggest                            
                    }
                }
            })
    
            suggestView.events.add('select', function(e){
                ymaps_data.suggest.focusout()
                let val = e.get('item').value
    
                ymaps_data.search(val)              
            })
    
            ymaps_data.suggest.on('change', function(){
                let val = ymaps_data.suggest.val()
    
                ymaps_data.search(val)
            })
    
            ymaps_data.search = (address, create_placemark = true, zoom = 17) => {
                ymaps.geocode(address).then(function (res) {
                    let objs = res.geoObjects.toArray()
                    let coords = objs[0].geometry._coordinates                      
    
                    ymaps_data.map.setCenter(coords, zoom, {
                        duration: 500
                    })    
                })
            }
        })
    }

    function open_sidebar() {
        let sidebar = $('.map-sidebar')

        sidebar.addClass('show')
    }

    function close_sidebar() {
        let sidebar = $('.map-sidebar')

        sidebar.removeClass('show')
    }

    function open_company(id) {
        let sidebar = $('.map-sidebar')

        open_sidebar()

        if(window.opened_company != id) {
            window.opened_company = id
            window.opened_companies = ''

            sidebar.start_loading()

            requestAjax('map_sidebar_open_company', id, res => {
                sidebar.find('.sidebar-content').html(res)[0].scrollTop = 0
    
                $('.map-images-slider').slick({
                    prevArrow: '<button class="btn blur small square slider-arrow prev"><span class="icon icon-left"></span></button>',
                    nextArrow: '<button class="btn blur small square slider-arrow next"><span class="icon icon-right"></span></button>',
                })
    
                sidebar.stop_loading()
            })
        }
    }

    function open_companies(ids) {
        let sidebar = $('.map-sidebar')

        open_sidebar()

        if(window.opened_companies != ids.join(',')) {
            window.opened_company = ''
            window.opened_companies = ids.join(',')

            sidebar.start_loading()

            requestAjax('map_sidebar_companies_list', ids, res => {
                sidebar.find('.sidebar-content').html(res)[0].scrollTop = 0
    
                sidebar.stop_loading()
            })
        }
    }

    $(document).on('click', '.close-map-sidebar', close_sidebar)
    $(document).on('click', '.open-company', function(){
        let elem = $(this)
        let id = elem.data('id')

        open_company(id)
    })

    $(document).on('click', '.zoom-in', function(){
        let zoom = Math.min(20, ymaps_data.map.getZoom() + 1)
        let coords = ymaps_data.map.getCenter()

        ymaps_data.map.setCenter(coords, zoom, {
            duration: 300
        })
    })

    $(document).on('click', '.zoom-out', function(){
        let zoom = Math.max(6, ymaps_data.map.getZoom() - 1)
        let coords = ymaps_data.map.getCenter()

        ymaps_data.map.setCenter(coords, zoom, {
            duration: 300
        })
    })

    $(document).on('click', '.my-position', function(){
        ymaps.geolocation.get().then(location => {
            ymaps_data.map.setCenter(location.geoObjects.position, 17, {
                duration: 500
            })
        })
    })

    $(document).on('click', '.toggle-ruler', function(){
        let elem = $(this)
        let state = ymaps_data.ruler.getState()

        if(state) {
            elem.removeClass('active')
            ymaps_data.ruler.disable()
        } else {
            elem.addClass('active')
            ymaps_data.ruler.enable()
        }

    })

}

$(document).on('click', '.toggle-map-search', function(){
    let fields = $('.field.hide-mobile')

    fields.stop().slideToggle(200)
})
$(document).on('click', '.delete-nanny', function(){
    let elem = $(this)
    let id = elem.data('id')

    addNotif({
        title: 'Удаление резюме',
        text: `Вы уверены что хотите удалить резюме`,
        color: 'red',
        icon: 'trash',
        btns: [
            {
                text: 'Удалить',
                type: 'text',
                color: 'red',
                onClick: (popup) => {
                    closeNotif(popup)

                    requestAjax('init_delete_nanny', id, () => {
                        window.location.reload()
                    })
                }
            },
            {
                text: 'Отмена',
                type: 'text',
                style: 'outline',
                onClick: (popup) => {
                    closeNotif(popup)
                }
            }
        ]
    })

})
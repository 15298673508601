let chart = $('#stats_chart')

if (chart.length) {
    let legends = $('.chart-legend')
    let period_toggler = $('.change-chart-period')
    let company_input = $('#chart_company')
    let ru = {
        "name": "ru",
        "options": {
          "months": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
          ],
          "shortMonths": [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек"
          ],
          "days": [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота"
          ],
          "shortDays": ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          "toolbar": {
            "exportToSVG": "Сохранить SVG",
            "exportToPNG": "Сохранить PNG",
            "exportToCSV": "Сохранить CSV",
            "menu": "Меню",
            "selection": "Выбор",
            "selectionZoom": "Выбор с увеличением",
            "zoomIn": "Увеличить",
            "zoomOut": "Уменьшить",
            "pan": "Перемещение",
            "reset": "Сбросить увеличение"
          }
        }
    }

    chart[0].chart = new ApexCharts(chart[0], {
        series: [

        ],
        chart: {
            animations: {
                enabled: false
            },
            height: 500,
            type: 'area',
            toolbar: {
                show: false,
            },
            locales: [ru],
            defaultLocale: 'ru',
            fontFamily: 'Gilroy, sans-serif'
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#1B998B'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        tooltip: {
            x: {
                format: 'dd.MM.yyyy'
            },
        },
        legend: {
            show: false
        }
    })
    chart[0].chart.render()

    Object.defineProperty(chart[0], 'data', {
        set: (data) => {
            let labels = data.labels
            let series = []
            let info = data.data

            if(labels.length == 1) {
                let date = labels[0]
                labels = [date + ' 00:00:00', date + ' 23:59:59'] 
            }

            for (let key in info) {
                let label = chart.data(key)
                let stats = Object.values(info[key])

                if(stats.length == 1) {
                    stats = [stats[0], stats[0]] 
                }

                series.push({
                    data: Object.values(stats),
                    name: label
                })
            }

            chart[0].chart.updateSeries(series)
            chart[0].chart.updateOptions({
                xaxis: {
                    type: 'datetime',
                    categories: labels,
                    labels: {
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
            })

            return this.dataset = data
        },
        get: () => {
            return this.dataset
        }
    })

    legends.change(function(){
        let elem = $(this)
        let name = elem.data('name')

        chart[0].chart.toggleSeries(name)
    })

    function update_chart() {
        let value = $('.change-chart-period.active').data('value')
        let now = new Date()
        let from = now.Ymd()
        let timestamp = now.getTime()
        let data = {from}
        let company = company_input.length ? company_input.getValue() : window.post_id

        localStorage.setItem('period', value)

        if(company) {
            localStorage.setItem('company', company)
        } else {
            localStorage.removeItem('company')
        }

        if(value == 1) {
            data.to = from
        } else if(value == 2) {
            let date = new Date(timestamp - 604800000).Ymd()
            data.to = date
        } else if(value == 3) {
            let date = new Date(timestamp - 2629746000).Ymd()
            data.to = date
        } else if(value == 4) {
            let date = new Date(timestamp - 15768000000).Ymd()
            data.to = date
        } else if(value == 5) {
            let date = new Date(timestamp - 31536000000).Ymd()
            data.to = date
        }

        if(company) {
            data.ids = [company]
        }

        requestAjax('ajax_get_total_stats', data, res => {
            res = JSON.parse(res)
            chart[0].data = res
        })
    }

    period_toggler.click(function(){
        let elem = $(this)
        let siblings = elem.siblings()
        let value = elem.data('value')

        siblings.removeClass('active')
        elem.addClass('active')

        chart[0].period = value
        update_chart()
    })

    let selected_company = localStorage.getItem('company') || ''
    let selected_period = localStorage.getItem('period') || 2
    let active_period = $('.change-chart-period[data-value="' + selected_period + '"]')

    if(window.post_id) {
        selected_company = window.post_id
    }

    active_period.addClass('active')
    if(company_input.length) {
        company_input[0].selected = selected_company
    }
    update_chart()
    if(company_input.length) {
        company_input.on('selected', update_chart)
    }
}
const sliders = {
    '.single-company-slider': {
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="btn blur square slider-arrow prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn blur square slider-arrow next"><span class="icon icon-right"></span></button>',
        responsive: [
            {
                breakpoint: 1024.99,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767.99,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    },
    '.single-slider': {
        prevArrow: '<button class="btn blur square slider-arrow prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn blur square slider-arrow next"><span class="icon icon-right"></span></button>',
    },
    '.companies-slider': {
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: '<button class="btn square medium prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn square medium next"><span class="icon icon-right"></span></button>',
        infinite: false,
        responsive: [
            {
                breakpoint: 1024.99,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767.99,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    },
    '.small-companies-slider': {
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="btn square medium prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn square medium next"><span class="icon icon-right"></span></button>',
        infinite: false
    },
    '.rec-slider': {
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: '<button class="btn square medium prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn square medium next"><span class="icon icon-right"></span></button>',
        infinite: false,
        responsive: [
            {
                breakpoint: 767.99,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    },
    '.companies > .line': {
        arrows: false,
        mobileFirst: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 767.99,
                settings: 'unslick'
            }
        ]
    }
}

$.each(sliders, (name, options) => {
    const slider = $(name)

    if (slider.length) {
        slider.slick(options)
    }
})
$(document).on('click', '.select-tariff .tariff-item', function(){
    let elem = $(this)
    let id = elem.data('id')
    let siblings = elem.siblings()

    window.tariff = id
    elem.addClass('active dark')
    siblings.removeClass('dark active')
})

$(document).on('click', '.extend-btn', function(){
    let elem = $(this)

    if(window.tariff) {
        elem.disable(true)
        requestAjax('ajax_init_add_payment', {
            tariff_id: window.tariff,
            post_id: window.post_id,
            type: 'tariff',
        }, payment_id => {
            window.location.href = '/dashboard/payments/payment/?payment_id=' + payment_id
        })
    } else {
        addNotif({
            title: 'Ошибка',
            text: 'Выберите тариф',
            icon: 'error',
            color: 'red',
            timeout: 4000
        })
    }
})
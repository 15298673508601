$(document).on('submit', '#commentform', (e) => {
    let btn = $('#submit')
    let comment_field = $('#comment')
    let rating_field = $('#comment_rating')

    if(!comment_field.check()) {
        let data = {
            post_id: window.post_id,
            comment: comment_field.getValue(),
            rating: rating_field.getValue(),
        }

        btn.disable(true)
        requestAjax('init_send_comment', data, (res) => {
            btn.enable()
            btn.disable()
            addNotif({
                title: 'Спасибо',
                text: 'Ваш отзыв был отправлен на модерацию',
                color: 'green',
                icon: 'success'
            })
            // window.location.reload()
        })
    }

    e.preventDefault()
})

$(document).on('click', '.reply-btn', function(){
    let elem = $(this)
    let form = elem.next()

    elem.hide()
    form.show()
    form.find('textarea').focus()
})

$(document).on('click', '.send-reply', function(){
    let elem = $(this)
    let id = elem.data('id')
    let textarea = elem.prev()
    let value = textarea.getValue()
    let data = {
        id: id,
        value: value,
        post_id: window.post_id,
    }

    if(!textarea.check()) {
        textarea.disable()
        elem.disable()

        requestAjax('init_send_reply', data, () => {
            addNotif({
                title: 'Спасибо',
                text: 'Ваш ответ был отправлен на модерацию',
                color: 'green',
                icon: 'success'
            })
    
            setTimeout(() => {
                window.location.reload()
            }, 4000)
        })
    }
})
// $('.inline-date').datepicker({
//     prevText: '<button class="prev-month btn medium ghost-theme"><span class="icon icon-left"></span></button>',
//     nextText: '<button class="next-month btn medium ghost-theme"><span class="icon icon-right"></span></button>',
//     dateFormat: "dd.mm.yy",
//     monthNames: [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
//     firstDay: 1,
//     dayNamesMin: [ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
//     selectOtherMonths: true,
//     showOtherMonths: true
// })

$.fn.phoneMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: '+{7} (000) 000-00-00',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            })
        
            mask.type = 'phone'
            elem[0].mask = mask
        })
    }
    
}

$.fn.timeMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: 'H:M',
                lazy: false,
                placeholderChar: '_',
                blocks: {
                    H: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 23,
                        maxLength: 2,
                    },
                    M: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 59,
                        maxLength: 2,
                    }
                }
            })
        
            mask.type = 'time'
            elem[0].mask = mask
        })
    }
}

$.fn.priceMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: Number,
                min: 0,
                max: 10000000,
                thousandsSeparator: ' '
            })
        
            mask.type = 'price'
            elem[0].mask = mask
        })
    }
}

$.fn.numberMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: Number,
                min: 0,
                max: 10000000,
                thousandsSeparator: ''
            })
        
            mask.type = 'number'
            elem[0].mask = mask
        })
    }
}

$.fn.percentsMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: Number,
                min: 0,
                max: 100,
                thousandsSeparator: ''
            })
        
            mask.type = 'percents'
            elem[0].mask = mask
        })
    }
}

$.fn.dateMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: Date,
                lazy: false,
                placeholderChar: '_'
            })

            // elem.datepicker({
            //     prevText: '<button class="prev-month btn medium ghost-theme"><span class="icon icon-left"></span></button>',
            //     nextText: '<button class="next-month btn medium ghost-theme"><span class="icon icon-right"></span></button>',
            //     dateFormat: "dd.mm.yy",
            //     monthNames: [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
            //     firstDay: 1,
            //     dayNamesMin: [ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
            //     selectOtherMonths: true,
            //     showOtherMonths: true
            // })
        
            mask.type = 'date'
            elem[0].mask = mask

            elem.on('keyup', function() {
                let elem = $(this)
                let val = elem.val()
            
                // if(val.indexOf('_') == -1) {
                //     elem.datepicker( 'hide' )
                // }
            })

        })
    }
}

$.fn.slugMask = function() {
    if(this.length) {
        this.each(function() {
            let elem = $(this)

            let mask = new IMask(elem[0], {
                mask: /^\w+$/,
            })
        
            mask.type = 'slug'
            elem[0].mask = mask
        })
    }
}

$('.price-mask').priceMask()
$('.number-mask').numberMask()
$('.percents-mask').percentsMask()
$('.time-mask').timeMask()
$('.date-input, .date-mask').dateMask()
$('.slug-mask').slugMask()
$('.phone-mask').phoneMask()
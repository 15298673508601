let dropdown_togglers = $('.toggle-dropdown')

if(dropdown_togglers.length) {
    dropdown_togglers.each(function(){
        let elem = $(this)
        let parent = elem.parent()
        let dropdown = elem.siblings('.dropdown, .dropdown-menu')
        let close = dropdown.find('.close-dropdown')

        elem.click(function(){
            if(elem.hasClass('only-show')) {
                dropdown.stop().fadeIn(200)
                parent.addClass('opened')
            } else {
                dropdown.stop().fadeToggle(200)
                parent.toggleClass('opened')
            }
        })

        close.click(function(){
            dropdown.stop().fadeOut(200)
            dropdown.trigger('close')
            parent.removeClass('opened')
        })

        $(document).mouseup( function(e){
            if ( !parent.is(e.target) && parent.has(e.target).length === 0 ) {
                dropdown.stop().fadeOut(200)
                dropdown.trigger('close')
                parent.removeClass('opened')
            }
        })
    })
}
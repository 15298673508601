$(document).on('click', '.apply-filters', archive_search)

$(document).on('click', '.sidebar-header', function(){
    let elem = $(this)
    let parent = elem.parent()
    let content = elem.next()
    let width = $(window).width()

    if(width < 1024.99) {
        parent.toggleClass('show')
        content.stop().slideToggle(300)
    }
})

$(document).on('click', '.toggle-filters', function(){
    let elem = $(this)
    let parent = elem.parent('section')
    let wrapper = elem.next()

    parent.toggleClass('show')
    wrapper.stop().fadeToggle()

    $('.resize').resize()
})
function register(callback){
    let firstNameField = $('#register_firstname')
    let lastNameField = $('#register_lastname')
    let phoneField = $('#register_phone')
    let emailField = $('#register_email')
    let passwordField = $('#register_password')
    let passwordConfirmField = $('#register_password_confirm')
    let checkbox = $('#register_accert')
    let btn = $('#register_send')
    let errors = 0
    let checkFields = [firstNameField, lastNameField, phoneField, emailField, passwordField, passwordConfirmField, checkbox]

    for(field of checkFields){
        errors += field.check()
    }

    if(!errors){
        let firstName = firstNameField.getValue()
        let lastName = lastNameField.getValue()
        let phone = phoneField.getValue()
        let email = emailField.getValue()
        let password = passwordField.getValue()
        let passwordConfirm = passwordConfirmField.getValue()
        let data = {firstName, lastName, phone, email, password, passwordConfirm}

        if(password != passwordConfirm){
            
            addNotif({
                title: 'Ошибка',
                text: 'Пароли должны совпадать',
                icon: 'close',
                color: 'red',
                timeout: 4000
            })

            passwordConfirmField.addClass('error')
        }else{
            requestAjax('register', data, (res) => {
                res = JSON.parse(res)
                
                if(res.res == 'error'){
                    displayAuthErrors(res.fields)
                    btn.enable()
                }else{
                    callback()
                }

            })

            btn.disable(true)
        }
    }

}
$(document).on('click', '#register_send', (e) => {
    e.preventDefault()

    register(() => {
        window.location.href = '/dashboard'
    })
})
$('#save_edit').click(function(e){
    let elem = $(this)
    let name_field = $('#edit_name')
    let surname_field = $('#edit_surname')
    let phone_field = $('#edit_phone')
    let email_field = $('#edit_email')
    let password_field = $('#edit_password')
    let confirm_field = $('#edit_password_confirm')
    let avatar_field = $('#profile_avatar')
    let check_fields = [name_field, surname_field, phone_field, email_field]
    let errors = 0

    for(let field of check_fields){
        errors += field.check()
    }

    e.preventDefault()
    
    if(!errors){
        let name = name_field.getValue()
        let surname = surname_field.getValue()
        let phone = phone_field.getValue()
        let email = email_field.getValue()
        let password = password_field.getValue()
        let confirm = confirm_field.getValue()
        let results = {name, surname, phone, email}
        let send = true

        if(password){
            if(password != confirm){
                addNotif({
                    title: 'Ошибка',
                    text: 'Пароли не совпадают',
                    icon: 'close',
                    color: 'red',
                    timeout: 3500
                })

                send = false
            }else{
                results.password = password
            }
        }

        if(avatar_field.length) {
            let avatar = avatar_field[0].avatar

            if(avatar) {
                results.avatar = avatar
            }
        }

        if(send){
            elem.disable(true)

            requestAjax('edit_profile', results, function(res){
                elem.enable()

                if(res){
                    res = JSON.parse(res)
                    displayAuthErrors(res)
                }else{
                    addNotif({
                        title: 'Редактирование',
                        text: 'Ваши данные успешно изменены',
                        icon: 'success',
                        color: 'green',
                        timeout: 3500
                    })
                }
            })
        }

    }
})
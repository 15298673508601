$(document).on('click', '.payment-method:not(.disabled)', function(){
    let elem = $(this)
    let siblings = elem.siblings()
    let type = elem.data('type')
    let actions = $('.payment-methods-actions')

    siblings.removeClass('active')
    elem.addClass('active')

    actions.children().hide()
    actions.find('[data-type="' + type + '"]').show()
})

$(document).on('click', '.pay-with-balance', function(){
    let elem = $(this)
    
    elem.disable('true')
    requestAjax('init_pay_with_balance', window.payment_id, () => {
        window.location.reload()
    })
})

$(document).on('click', '.decline-payment', function(){
    let elem = $(this)

    elem.disable(true)
    requestAjax('init_decline_payment', window.payment_id, () => {
        window.location.reload()
    })
})
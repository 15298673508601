let search_fields = $('.catalog-search .select-field textarea')

if(search_fields.length) {
    let core_search = $('.search-form')

    search_fields.each(function(){
        let search = $(this)
        let search_key = search.data('search')
        let search_array = window.search[search_key]
        let search_wrapper = search.parents('.select-field')
        let search_dropdown = search_wrapper.find('.dropdown')
        let reset_btn = search_dropdown.find('.reset')
        let items = search_dropdown.find('.dropdown-item')

        Object.defineProperty(search[0], 'selected', {
            set: (data) => {
                this.selected_item = data

                if(!search.hasClass('used')) {
                    search[0].hide_details = true
                }

                this.render()
            },  
            get: () => {
                return this.selected_item
            }
        })

        search[0].render = () => {
            let selected = search[0].selected

            search_dropdown.find('.search-list').remove()
            search_dropdown.find('.dropdown-list').hide()
            search_dropdown.find('.dropdown-item').removeClass('active opened').hide()
            search_dropdown.find('.title').removeClass('dark')

            if(!search.search_mode) {
                if(selected) {
                    let active = search_dropdown.find('.dropdown-item[data-id="' + selected + '"]')
                    let parents = active.parents('.dropdown-item, .dropdown-list')
                    let active_text_array = [active.data('value')]
    
                    parents.each(function(){
                        let elem = $(this)
                        let text = elem.data('value')
    
                        if(text) {
                            active_text_array.push(text)
                        }
                    })
    
                    let active_text = active_text_array.reverse().join(', ')
    
                    active.addClass('active')
                    active.children('.title').addClass('dark')
                    
                    if(search[0].hide_details || !active.children('.dropdown-list').length) {
                        active.children().show()
                        active.siblings().show()
                        active.show()
                        parents.show().addClass('opened')
                    } else {
                        let items_drodown = active.children('.dropdown-list')
    
                        parents.show().addClass('opened')
                        active.show()
                        items_drodown.show().children().show()
    
                        active.addClass('opened')
                    }
    
                    search.val(active_text).resize()
    
                } else {
                    let active = search_dropdown.find('.dropdown-list').eq(0)
                    active.show()
                    active.children().show()
                    search.val('').resize()
                }
            } else {
                let val = search.getValue().toLowerCase().replaceAll(' ', '').replaceAll(',', '')
                let found = []
                let structure = []
                
                for(let item of search_array) {
                    if(item.full.toLowerCase().replaceAll(' ', '').replaceAll(',', '').indexOf(val) != -1) {
                        found.push(item)
                    }
                }

                found = found.slice(0, 5)

                if(found.length) {
                    for(let item of found) {
                        structure.push(`<div class="search-item${ selected == item.id ? ' active' : '' }" data-id="${item.id}">${item.full}</div>`)
                    }
                } else {
                    structure.push(`
                        <div class="empty-block small"><span class="icon icon-error"></span><div class="h6">Ничего не найдено</div></div>
                    `)
                }

                structure = `<div class="search-list">${structure.join('')}</div>`
                search_dropdown.prepend(structure)

                search_dropdown.find('.search-item').click(function(){
                    let elem = $(this)
                    let id = elem.data('id')
                    
                    search.search_mode = false
                    search[0].selected = id
                })
            }   

        }

        let selected = search.data('selected') || 0
        search[0].selected = selected

        items.find('.title').click(function(e){
            let old_selected = search[0].selected
            let target = $(e.target)
            let elem = $(this).parent()
            let id = elem.data('id')

            if(target[0].tagName == 'SPAN') {
                if( old_selected == id ) {
                    search[0].hide_details = !search[0].hide_details
                } else {
                    let opened_parent = target.parent('.title').parent('.opened')
                    
                    if(opened_parent.length) {
                        search[0].hide_details = true
                    } else {
                        search[0].hide_details = false
                    }
                }

            } else if(search[0].selected != id) {
                search[0].hide_details = true
            }

            search[0].selected = id
        })

        reset_btn.click(() => {
            search[0].selected = 0
        })

        search.on('input', function(){
            search.search_mode = true

            search[0].render()
        })

        search_dropdown.on('close', function(){
            search.search_mode = false
            search[0].render()
        })

        search.click(() => {
            search.addClass('used')
        })
    })

    if(core_search.length) {
        core_search.on('submit', (e) => {
            let val = core_search.find('input').getValue()
            e.preventDefault()

            archive_search(val)
        })
    }
}

$(document).on('click', '.search-send', function(){
    archive_search()
})

function archive_search(query) {
    let wrapper = $('.catalog-search')
    let prefix = wrapper.data('prefix')
    let selected_category = $('#search_category').length ? $('#search_category')[0].selected : ''
    let selected_location = $('#search_location').length ? $('#search_location')[0].selected : ''
    let selected = []
    let url = new URL(window.location.href).origin + '/' + prefix
    let raw_attrs = {}

    if(!query) {
        query = $('#search_name')

        if(query.length) {
            query = query.getValue()
        } else {
            query = ''
        }

    }

    if(typeof query == 'string') {
        raw_attrs.search = query
    }

    if(selected_category) {
        let ids = [selected_category]
        let subcategory = window.categories.subcategory_category[selected_category]

        if(subcategory) {
            ids.unshift(subcategory)
        }
        
        for(let id of ids) {
            let slug = window.categories.slugs[id]
            selected.push(slug)
        }

    }

    if(selected_location) {
        let ids = [selected_location]
        let district = window.locations.microdisctrict_disctrict[selected_location]

        if(district) {
            ids.unshift(district)

            let region = window.locations.disctrict_region[district]
            if(region) {
                ids.unshift(region)
            }
        } else {
            let region = window.locations.disctrict_region[selected_location]

            if(region) {
                ids.unshift(region)
            }
        }

        for(let id of ids) {
            let slug = window.locations.slugs[id]
            selected.push(slug)
        }

    }

    let sidebar = $('.archive-sidebar')
    let filters = []
    let groups = sidebar.find('.filter-group')

    groups.each(function(){
        let group = $(this)
        let type = group.data('type')
        let group_slug = group.data('filter')

        if(type == 'checkbox') {
            let checked = group.find('input:checked')

            if(checked.length) {
                let filtred = []
                checked.each(function(){
                    let elem = $(this)
                    let slug = elem.data('slug')
        
                    filtred.push(slug)
                })
    
                filters.push(group_slug + ':' + filtred.join(','))
            }
        } else if(type == 'select') {
            let input = group.find('input')
            let selected = input[0].selected

            if(selected !== '' && selected != undefined) {
                filters.push(group_slug + ':' + selected)
            }
        } else if(type == 'range') {
            let range = group.find('.noUi-target')
            let min = range.data('min')
            let max = range.data('max')
            let value = range[0].slider.get()

            if(min != Math.round(value[0]) || max != Math.round(value[1])) {
                filters.push('birth:' + Math.round(value[0]) + '-' + Math.round(value[1]))
            }
        }
        
    })

    if(filters.length) {
        raw_attrs.filters = filters.join(';')
    }

    if(selected.length) {
        url = [url, ...selected].join('/')
    }

    url += '/'

    if(Object.keys(raw_attrs).length) {
        let results = []

        for(let key in raw_attrs) {
            let value = $.trim(raw_attrs[key])

            if(value !== '') {
                results.push(key + '=' + value)
            }
        }

        if(results.length) {
            url += '?' + results.join('&')
        }
    }

    window.location.href = url
}
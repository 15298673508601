function update_fav() {
    
    simpleAjax('ajax_get_favs', res => {
        res = JSON.parse(res)

        let btns = $('.add-to-fav')

        btns.removeClass('active')
        
        if(res.length) {
            for(let id of res) {
                let btn = $('.add-to-fav[data-id="' + id + '"]')

                btn.addClass('active')
            }
        }
    })

}

$(document).on('click', '.add-to-fav', function(){
    let elem = $(this)
    let id = elem.data('id')

    requestAjax('add_to_fav', id, () => {
        update_fav()
    })
})
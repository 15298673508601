$(document).on('click', '.delete-company', function(){
    let elem = $(this)
    let id = elem.data('id')
    let name = elem.data('name')

    addNotif({
        title: 'Удаление компании',
        text: `Вы уверены что хотите удалить комипанию '${name}'`,
        color: 'red',
        icon: 'trash',
        btns: [
            {
                text: 'Удалить',
                type: 'text',
                color: 'red',
                onClick: (popup) => {
                    closeNotif(popup)

                    requestAjax('init_delete_company', id, () => {
                        window.location.reload()
                    })
                }
            },
            {
                text: 'Отмена',
                type: 'text',
                style: 'outline',
                onClick: (popup) => {
                    closeNotif(popup)
                }
            }
        ]
    })

})
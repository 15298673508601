if (typeof ymaps !== 'undefined' && $('#yandex_map').length) {
    let ymaps_data = {
        suggest: $('#company_location'),
        suggest_id: 'company_location',
        map: $('#yandex_map'),
        map_id: 'yandex_map',
        ready: false,
        onload: []
    }

    window.ymaps_data = ymaps_data

    ymaps.ready(() => {
        ymaps_data.map = new ymaps.Map('yandex_map', {
            center: [43.238293, 76.945465],
            zoom: 12,
            type: 'yandex#map',
            controls: ['geolocationControl', 'zoomControl']
        }, {
            searchControlProvider: 'yandex#search',
            yandexMapDisablePoiInteractivity: true
        },{suppressMapOpenBlock: true})

        ymaps_data.ready = true

        suggestView = new ymaps.SuggestView(ymaps_data.suggest_id, {
            provider: {
                suggest : function(request) {
                    request = 'Казахстан, ' + request
                    
                    let suggest = new ymaps.suggest(request)
                    suggest.then(function (items){

                        $.each(items, function(index, key){                                  
                            key.value = key.value.replace(/(.*?), /, '')
                            key.displayName = key.displayName.split(', ').slice(0, -1).join(', ')                                                              
                        })
                        
                    })             
                
                    return suggest                            
                }
            }
        })

        suggestView.events.add('select', function(e){
            ymaps_data.suggest.focusout()
            let val = e.get('item').value

            ymaps_data.search(val)              
        })

        ymaps_data.suggest.on('change', function(){
            let val = ymaps_data.suggest.val()

            ymaps_data.search(val)
        })

        ymaps_data.map.events.add('click', function (e) {       
            let coords = e.get('coords')

            ymaps_data.set_coords(coords)
        })

        function createPlacemark(coords) {
            return new ymaps.Placemark(coords, {
                iconCaption: 'поиск...'
            }, {
                preset: 'islands#redDotIconWithCaption',
                draggable: true,
                iconCaptionMaxWidth: '1000',
                hasBalloon : false
            })
        }

        ymaps_data.get_address = (coords) => {
            let address_line = []
            ymaps_data.coords = coords.join(',')
            ymaps_data.placemark.properties.set('iconCaption', 'поиск...')

            ymaps.geocode(coords, {kind: 'district'}).then(function (res) {
                let districtObject = res.geoObjects.get(0)
                let district_components = districtObject.properties._data.metaDataProperty.GeocoderMetaData.Address.Components
                let filtred_components = {}
                let old_districts = []

                for(let component of district_components) {
                    let kind = component.kind
                    let name = component.name

                    if(kind == 'province') {
                        address_line.push(name)
                    }

                    if(kind == 'district') {
                        address_line.push(name)
                        old_districts.push(name)
                    }
                    filtred_components[component.kind] = component.name
                }

                ymaps.geocode(coords, {kind: 'house'}).then(function (res) {
                    let firstGeoObject = res.geoObjects.get(0)
                    let components = firstGeoObject.properties._data.metaDataProperty.GeocoderMetaData.Address.Components
                    let filtred_components = {}
    
                    for(let component of components) {
                        let kind = component.kind
                        let name = component.name

                        if(kind == 'house' || kind == 'street') {
                            address_line.push(name)
                        }

                        if(kind == 'district' && old_districts.indexOf(name) == -1) {
                            address_line.push(name)
                            old_districts.push(name)
                        }

                        filtred_components[component.kind] = component.name
                    }

                    let address = address_line.join(', ')

                    ymaps_data.map.setCenter(coords, 17)
                    
                    address = address.replace('микрорайон', 'мкр.')
                    address = address.replace('район', 'р-н')
                    address = address.replace('улица', 'ул.')
                    address = address.replace('жилой комплекс', 'жк.')
                    address = address.replace('проспект', 'пр.')
                    
                    ymaps_data.placemark.properties
                        .set({
                            iconCaption: address,
                            balloonContent: address,                    
                        })
    
                    ymaps_data.address = address
                    ymaps_data.suggest.val(address)      
                    ymaps_data.suggest.trigger('location_selected')     
                })
            })
        }

        ymaps_data.search = (address, create_placemark = true, zoom = 17) => {
            ymaps.geocode(address).then(function (res) {
                let objs = res.geoObjects.toArray()
                let coords = objs[0].geometry._coordinates                      

                if(create_placemark) {
                    if (ymaps_data.placemark) {
                        ymaps_data.placemark.geometry.setCoordinates(coords)
                    }else{
                        ymaps_data.placemark = createPlacemark(coords)
                        ymaps_data.map.geoObjects.add(ymaps_data.placemark)
                    }
                    ymaps_data.get_address(coords, zoom)   
                } else {
                    ymaps_data.map.setCenter(coords, zoom)
                }     
            })
        }

        ymaps_data.set_coords = (coords, zoom = 17) => {
            if (ymaps_data.placemark) {
                ymaps_data.placemark.geometry.setCoordinates(coords)
            }
            else {
                ymaps_data.placemark = createPlacemark(coords)
                ymaps_data.map.geoObjects.add(ymaps_data.placemark)

                ymaps_data.placemark.events.add('dragend', function () {
                    ymaps_data.get_address(ymaps_data.placemark.geometry.getCoordinates())
                })
            }

            ymaps_data.get_address(coords)
        }

        ymaps_data.remove_placemark = () => {
            ymaps_data.map.geoObjects.removeAll()
            ymaps_data.placemark = null
        }

        if(window.ymaps_data.onload.length) {
            for(let func of window.ymaps_data.onload) {
                func()
            }
        }

        let search = ymaps_data.suggest.data('search')

        if(search) {
            ymaps_data.search(search)
        }

    })
}
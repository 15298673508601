$(document).on('click', '.clear-input', function() {
    let elem = $(this)
    let input = elem.siblings('input')
    let type = input.data('type')

    if(type == 'select') {
        input[0].selected = ''
    } else {
        input.val('').change()
    }
})

$.fn.check = function() {
    let errors = 0
    let type = this.attr('type')

    if(this.hasClass('inline-date')) {
        let val = this.datepicker('getDate')

        if(!val) {
            errors++
            addNotif({
                title: 'Ошибка',
                text: 'Выберите дату',
                icon: 'close',
                color: 'red',
                timeout: 2000
            })
        }
    }else if(this.hasClass('email')){
        let val = $.trim(this.val())
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
        let emailTest = regex.test(val)

        if(!emailTest && val == ''){
            this.addClass('error')
            errors++
        }else if(!emailTest){
            this.addClass('error')
            errors++
        }else{
            this.removeClass('error')
        }

    }else if(type == 'checkbox'){
        if(this.prop('checked')){
            this.removeClass('error')
        }else{
            this.addClass('error')
            errors++
        }
    }else{
        let val = $.trim(this.val())
        let isMasked = this[0].mask ? true : false
    
        if(val) {
            this.removeClass('error')
        }else{
            errors++
            this.addClass('error')
        }
    
        if(isMasked && !errors) {
            if(val.indexOf('_') == -1) {
                this.removeClass('error')
            }else{
                errors++
                this.addClass('error')
            }
        }
        
        this.on('input', () => {
            this.removeClass('error')
        })
    
    }

    return errors
}

$.fn.getValue = function() {
    if(this.hasClass('inline-date')) {
        return this.datepicker('getDate')
    }else if(this.hasClass('select-input')) {
        let val = this[0].selected

        if(val) {
            return val
        }
    }else if(this.hasClass('link-field')) {
        let val = this.val()

        if(val.indexOf('https://') == -1 && val.indexOf('http://') == -1) {
            val = val.replace('https:/', '')
            val = val.replace('http:/', '')
            val = 'https://' + val
        }

        if(val.charAt(val.length - 1) != '/') {
            val += '/'
        }

        let check = val.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)

        return check ? val : ''
    }else{
        let hasMask = this[0].mask

        if(hasMask) {
            if(this[0].mask.type == 'time' || this[0].mask.type == 'date') {
                let val = $.trim(this.val())

                if(!val) {
                    return ''
                }else if(val.indexOf('_') != -1) {
                    return ''
                }

                return val
            }else if(this[0].mask.type == 'slug') {
                let val = $.trim(this.val())
                return val
            }else{
                return $.trim(this[0].mask.unmaskedValue)
            }
        }else{
            return $.trim(this.val())
        }
    }
}

$.fn.clear = function() {
    if(this.hasClass('inline-date')) {
        return this.datepicker('getDate')
    }else{
        let hasMask = this[0].mask

        if(hasMask) {
            this[0].mask.unmaskedValue = ''
        }else{
            this.val('')
        }
    }

    return this
}

$.fn.resize = function(){
    if(this && this.length){
        $(this).each(function(){
            let elem = $(this)
        
            elem.css('height', '0px')
            elem.css('width', 'calc(100% + 1px)')
    
            let height = elem[0].scrollHeight
        
            elem.css('height', 'auto')
            let min_height = elem[0].clientHeight
    
            if(!elem[0].getAttribute('cols')) {
                min_height = 0
            }
    
            height = Math.max(height, min_height)
            elem.css('height', height)
            elem.css('width', '100%')
        })
    }

    return this
}

$(document).on('input change', '.resize', function(e){
    $(this).resize()
})

$('.resize').resize()

$(window).on('resize', () => {
    $('.resize').resize()
})

$(document).on('change input', 'input', function(){
    let elem = $(this)

    elem.removeClass('error')
})

function init_select() {
    let selects = $('.select-input:not(.inited)')

    if(selects.length) {
        selects.each(function(){
            let input = $(this)
            let indicator = input.siblings('.clear-input, .arrow')
            let selected = input.data('selected')
            let parent = input.parent()
            let dropdown = input.siblings('.dropdown-menu')

            selected = selected === '' ? '' : selected

            input.on('click', () => {
                dropdown.stop().fadeToggle(200)
            })

            input.addClass('inited')

            $(document).mouseup( function(e){
                if ( !parent.is(e.target) && parent.has(e.target).length === 0 ) {
                    dropdown.stop().fadeOut(200)
                }
            })

            input[0].selected = null

            Object.defineProperty(input[0], 'selected', {
                set: (data) => {
                    this.selected_id = data

                    this.value = ''
                    dropdown.find('.active').removeClass('active')

                    if(data !== '' && data !== undefined) {
                        let active = dropdown.find('[data-value="' + data + '"]')
                        let text = active.find('.text').text() || active.text()

                        active.addClass('active')
                        this.value = text

                        indicator.removeClass('clear-input arrow icon-down icon-close').addClass('clear-input icon-close')
                    } else {
                        indicator.removeClass('clear-input arrow icon-down icon-close').addClass('arrow icon-down')
                    }

                    if(!this.dont_trigger) {
                        $(this).trigger('selected')
                    } else {
                        this.dont_trigger = false
                    }

                    dropdown.stop().fadeOut(200)
                    return data
                },
                get: () => {
                    return this.selected_id
                }
            })

            input[0].refresh = (dont_trigger = false) => {
                if(dont_trigger) {
                    input[0].dont_trigger = true
                }
                input[0].selected = input[0].selected
            }

            if(selected !== '') {
                input[0].selected = selected
            }    
        })
    }
}

init_select()

$(document).on('click', '.select-dropdown .item', function(){
    let item = $(this)
    let value = item.data('value')
    let dropdown = item.parents('.dropdown-menu')
    let input = dropdown.siblings('input')

    input[0].selected = value

    if(input.hasClass('select-category')) {
        update_categories()
    }

    if(input.hasClass('select-location')) {
        update_locations()
    }
})

$(document).on('click', '.show-password', function() {
    let elem = $(this)
    let input = elem.prev()
    let type = input.attr('type')

    if(type == 'password') {
        input.attr('type', 'text')
    } else {
        input.attr('type', 'password')
    }
})

$(document).on('click', '.input-wrapper.rating .star', function(){
    let elem = $(this)
    let index = elem.index() + 1
    let wrapper = elem.parents('.input-wrapper')
    let input = wrapper.find('input')

    input.val(index)
    elem.addClass('active')
    elem.siblings().removeClass('active')
})

$(document).on('keyup', '.enter-submit, .cell-input', function(e){
    if(e.keyCode == 13){
        const input = $(this)

        if(e.target.nodeName == 'TEXTAREA'){
            if(e.ctrlKey){
                input.trigger('submit')
                input.blur()
            }
        }else if(e.target.nodeName != 'INPUT'){
            input.trigger('submit')
            if(input) {
                input.blur()
            }
        }else{
            input.trigger('submit')
            if(input) {
                input.blur()
            }
        }
    }
})

function init_range() {
    let ranges = $('.input-wrapper.range:not(.inited)')

    ranges.each(function(){
        let elem = $(this)
        let min = elem.data('min')
        let max = elem.data('max')
        let min_val = elem.data('value-min')
        let max_val = elem.data('value-max')
        let wrapper = elem.parents('.range-wrapper')
        let min_input = wrapper.find('input.min')
        let max_input = wrapper.find('input.max')

        elem[0].slider = noUiSlider.create(elem[0], {
            start: [min_val, max_val],
            connect: true,
            range: {min, max}
        })

        elem[0].slider.on('update', function (values, handle) {
            if(min_input.length) {
                min_input.val(Math.round(values[0]))
            }

            if(max_input.length) {
                max_input.val(Math.round(values[1]))
            }
        })
    })
}

init_range()

function scroll_to_error() {
    let error = $('.error')

    if(error.length) {
        let target = error.eq(0)

        if(!target.is(':visible')) {
            target = target.parents(':visible').eq(0)
        }

        let destination = target.offset().top - $('header.site-header').height() - 60
        jQuery("html,body").animate({
          scrollTop: destination
        }, 500)
    }
}

$(document).on('click', '.input-wrapper .toggle', function(){
    let elem = $(this)
    
    elem.toggleClass('active')
})
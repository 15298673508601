let tariffs_wrapper = $('.edit-tariffs .tariff-items')

if(tariffs_wrapper.length) {
    let label_name = tariffs_wrapper.data('label-name')
    let label_description = tariffs_wrapper.data('label-description')
    let label_price = tariffs_wrapper.data('label-price')
    let label_discount = tariffs_wrapper.data('label-discount')
    let label_term = tariffs_wrapper.data('label-term')
    let label_total = tariffs_wrapper.data('label-total')
    let label_tg = tariffs_wrapper.data('label-tg')
    let label_m = tariffs_wrapper.data('label-m')
    let label_empty = tariffs_wrapper.data('label-empty')
    let append_value = window.tariffs ? window.tariffs : []

    let tariffs_data = {
        wrapper: tariffs_wrapper,
        add_btn: $('.add-tariff'),
        save_btn: $('.save-tariffs'),
        template: `
        <div class="tariff-item" data-id="[id]" data-index="[index]">

            <div class="drag icon-drag"></div>

            <div class="info">
                <input type="text" class="ghost static mini enter-submit name" placeholder="${label_name}" value="[name]" data-key="name" data-index="[index]" >
                <input type="text" class="ghost static mini enter-submit description" placeholder="${label_description}" value="[description]" data-key="description" data-index="[index]" >
            </div>

            <div class="price">
                <div class="input-wrapper">
                    <div class="fixed-label">${label_tg}</div>
                    <input type="text" placeholder="${label_price}" class="medium enter-submit price-mask" value="[price]" data-index="[index]" data-key="price">
                </div>
            </div>

            <div class="discount">
                <div class="input-wrapper">
                    <div class="fixed-label">%</div>
                    <input type="text" placeholder="${label_discount}" class="medium enter-submit percents-mask" value="[discount]" data-index="[index]" data-key="discount">
                </div>
            </div>

            <div class="term">
                <div class="input-wrapper">
                    <div class="fixed-label">${label_m}</div>
                    <input type="text" placeholder="${label_term}" class="medium enter-submit number-mask" value="[term]" data-index="[index]" data-key="term">
                </div>
            </div>

            <div class="total">
                <div class="input-wrapper">
                    <div class="fixed-label">${label_tg}</div>
                    <input type="text" placeholder="${label_total}" class="medium price-mask" readonly value="[total]" data-index="[index]" data-key="total">
                </div>
            </div>

            <div class="status">
                <div class="small-toggler">
                    <input type="checkbox" id="enable_tariff_[index]" data-index="[index]" data-key="status"[is_checked]>
                    <label for="enable_tariff_[index]"></label>
                </div>
            </div>

            <button class="btn outline medium remove-tariff square" data-index="[index]"><span class="icon icon-close"></span></button>

        </div>
        `
    }

    window.tariffs_data = tariffs_data

    Object.defineProperty(tariffs_data, 'tariffs', {
        set: (data) => {
            if(!tariffs_data.dont_render) {
                let append = []

                if(data.length) {
                    let index = 0
                    for(let item of data) {
                        let {id = '', name = '', description = '', price = '', discount = '', term = '', total = 0, status = 'static'} = item
                        let structure = tariffs_data.template
                        let is_checked = status == 'static' ? ' checked' : ''
                        let replace = {index, id, name, description, price, discount, term, total, is_checked}

                        for(let key in replace) {
                            let value = replace[key]

                            structure = structure.replaceAll('[' + key + ']', value)
                        }

                        append.push(structure)
                        item.sort = index
                        index++
                    }
                } else {
                    append.push(`<div class="empty-block"><div class="icon icon-error"></div><div class="h6">${label_empty}</div></div>`)
                }

                tariffs_data.wrapper.html(append.join("\n"))

                $('.price-mask').priceMask()
                $('.number-mask').numberMask()
                $('.percents-mask').percentsMask()
            } else {
                tariffs_data.dont_render = false
            }

            return this.tariffs_list = data
        },
        get: () => {
            return this.tariffs_list
        }
    })

    tariffs_data.add = (data) => {
        let tariffs = tariffs_data.tariffs || []
        tariffs.push(data)
        tariffs_data.tariffs = tariffs
    }

    tariffs_data.remove = (index) => {
        let tariffs = tariffs_data.tariffs || []

        if(tariffs[index]) {
            tariffs.splice(index, 1);
            tariffs_data.tariffs = tariffs
        }
    }

    tariffs_data.check = (on_error, on_success) => {
        let errors = 0
        let tariffs = tariffs_data.tariffs || []
        let check_fields = ['name', 'description', 'price', 'term']

        if(tariffs.length) {
            for(let tariff of tariffs) {
                let index = tariff.sort

                for(let key of check_fields) {
                    let field = $(`input[data-key="${key}"][data-index="${index}"]`)

                    if(!tariff[key]) {
                        errors++
                        field.addClass('error')
                    } else {
                        field.removeClass('error')
                    }
                }

            }
        }

        if(errors) {
            on_error()
        } else {
            on_success()
        }
    }

    $(document).on('change', '.tariff-items input', function(){
        let elem = $(this)
        let key = elem.data('key')
        let value = elem.getValue()
        let index = elem.data('index')
        let tariffs = tariffs_data.tariffs
        let total_elem = $('input[data-key="total"][data-index="' + index + '"]')

        if(key == 'status') {
            value = elem.prop('checked') ? 'static' : 'disabled'
        }

        tariffs[index][key] = value

        let {price = 0, discount = 0, term = 0} = tariffs[index]
        let total = Math.round((price * term) * (1 - (discount * 0.01)))

        total_elem[0].mask.unmaskedValue = String(total)
        tariffs[index].total = total

        tariffs_data.dont_render = true
        tariffs_data.tariffs = tariffs
    })

    $(document).on('click', '.remove-tariff', function(){
        let elem = $(this)
        let index = elem.data('index')

        tariffs_data.remove(index)
    })

    tariffs_data.sortable = new Sortable(tariffs_data.wrapper[0], {
        animation: 150,
        handle: '.drag',
        ghostClass: 'sortable-ghost',
        dataIdAttr: 'data-index',
        onUpdate: function () {
            let array = tariffs_data.sortable.toArray()
            let old_order = tariffs_data.tariffs
            let new_order = []
            
            for(let index of array) {
                new_order.push(old_order[index])
            }

            tariffs_data.tariffs = new_order
        },
    })

    tariffs_data.add_btn.click(function(){
        tariffs_data.add({
            status: 'disabled',
            price: 1000,
            term: 1,
            discount: 0,
            total: 1000,
        })
    })

    tariffs_data.save_btn.click(function(){
        tariffs_data.check(() => {
            addNotif({
                title: 'Ошибка',
                text: 'Заполните все поля',
                icon: 'error',
                color: 'red',
                timeout: 4000
            })
        }, () => {
            tariffs_data.save_btn.disable(true)
            
            requestAjax('init_save_tariffs', tariffs_data.tariffs, () => {
                addNotif({
                    title: 'Сохранено',
                    text: 'Данные успешно сохранены',
                    icon: 'success',
                    color: 'green',
                    timeout: 4000
                })

                setTimeout(() => {
                    window.location.reload()
                }, 4000)
            })
        })
    })

    tariffs_data.tariffs = append_value
}
$(document).on('click', '.request-send', function(e) {
    e.preventDefault()

    let elem = $(this)
    let form = elem.parents('form')
    let popup = elem.parents('.popup')
    let fields = {
        name: 1,
        phone: 1,
        email: 1,
        message: 0,
    }
    let data = {}
    let errors = 0

    for(let key in fields) {
        let required = fields[key]
        let field = form.find('.' + key)

        if(field.length) {
            let value = field.getValue()

            if(required) {
                errors += field.check()
            }

            data[key] = value
        }
    }

    if(!errors) {
        elem.disable(true)
        data.post_id = window.post_id

        requestAjax('init_send_request', data, () => {
            for(let key in fields) {
                let field = form.find('.' + key)
        
                if(field.length) {
                    field.clear()
                }
            }

            addNotif({
                title: 'Заявка',
                text: 'Ваша заявка была отправлена',
                icon: 'success',
                color: 'green',
                timeout: 4000,
            })

            elem.enable()
            popup.stop().fadeOut(200)
        })
    }
})

$(document).on('click', '.request-info', function(e) {
    let elem = $(this)
    let item = elem.parent()
    let items = item.siblings()
    let dropdowns = items.find('.request-dropdown')
    let target = $(e.target)
    let dropdown = elem.next()

    if(!target.hasClass('prevent') && !target.parents('.prevent').length) {
        dropdown.stop().slideToggle(200)
        item.toggleClass('opened')

        items.removeClass('opened')
        dropdowns.stop().slideUp(200)
    }
})

$(document).on('change', '.change-field', function(){
    let elem = $(this)
    let val = elem.val()
    let id = elem.data('id')
    let field = elem.data('field')

    postAjax('init_change_request', {id, field, val})
})

$(document).on('click', '.change-status', function(){
    let elem = $(this)
    let id = elem.data('id')
    let status = elem.data('status')
    let item = elem.parents('.request-item')
    let data = {
        id: id,
        field: 'status',
        val: status,
    }

    item.stop().slideUp(200)

    requestAjax('init_change_request', data, () => {
        requestAjax('ajax_init_requests_count', window.status, res => {
            $('.requests-count').attr('data-count', res)
        })
    })
})
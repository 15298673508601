$(document).on('click', '.toggle-schedule', function(){
    let elem = $(this)
    let schedule = elem.siblings('.schedule-body')

    schedule.stop().slideToggle(200)
    elem.toggleClass('opened')
})

$(document).on('click', '.hidden-phone', function(e){
    let elem = $(this)
    let id = elem.data('id')
    let full = elem.data('full')

    elem.html(full)
    postAjax('ajax_init_views', {
        id: id,
        type: 'phone'
    })

    e.preventDefault()
})

if($(window).width() < 1024.99) {
    let description = $('.description-block')
    let sidebar = $('.single-company-body-sidebar')

    sidebar.prepend(description)
}
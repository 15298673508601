if (typeof ymaps !== 'undefined' && $('#static_map').length) {
    let ymaps_data = {
        coords: $('#static_map').data('coords'),
        address: $('#static_map').data('address'),
        map_id: 'static_map',
        ready: false
    }

    window.ymaps_data = ymaps_data

    ymaps.ready(() => {
        ymaps_data.map = new ymaps.Map('static_map', {
            center: [43.238293, 76.945465],
            zoom: 12,
            type: 'yandex#map',
            controls: ['geolocationControl', 'zoomControl']
        }, {
            searchControlProvider: 'yandex#search',
            yandexMapDisablePoiInteractivity: true
        },{suppressMapOpenBlock: true})

        ymaps_data.ready = true

        function createPlacemark(coords, address) {
            return new ymaps.Placemark(coords, {
                iconCaption: address
            }, {
                preset: 'islands#redDotIconWithCaption',
                draggable: true,
                iconCaptionMaxWidth: '1000',
                hasBalloon : false
            })
        }

        if(ymaps_data.coords) {
            let coords = ymaps_data.coords.split(',')
            let address = ymaps_data.address

            ymaps_data.map.geoObjects.add(createPlacemark(coords, address))
            ymaps_data.map.setCenter(coords, 17)
        } else if (ymaps_data.address) {
            ymaps.geocode(ymaps_data.address).then(function (res) {
                let objs = res.geoObjects.toArray()
                let coords = objs[0].geometry._coordinates                      

                ymaps_data.map.setCenter(coords, 14)    
            })
        }

    })
}
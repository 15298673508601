let nanny_form = {}
window.nanny_form = nanny_form

nanny_form.add_form = $('.add-nanny-form')
nanny_form.edit_form = $('.edit-nanny')

if(nanny_form.add_form.length) {
    nanny_form.steps_wrapper = $('.add-nanny-steps')
    nanny_form.steps = nanny_form.steps_wrapper.find('.step')
    nanny_form.sidebar = $('.sidebar')
    nanny_form.prev_btn = $('.step-prev')
    nanny_form.next_btn = $('.step-next')
    nanny_form.data = {}
    nanny_form.wrapper = nanny_form.steps_wrapper.parents('.content')

    Object.defineProperty(nanny_form, 'step', {
        set: (data) => {
            this.nanny_current_step = data
            
            let list = []
            let label = nanny_form.sidebar.data('label')
            
            nanny_form.steps.each(function(){
                let elem = $(this)
                let index = elem.index()
                let name = elem.data('name')
                let elem_classes = ['icon-check']

                if(index == data) {
                    elem_classes.push('current')
                }

                list.push(`<li class="${elem_classes.join(' ')}">${name}</li>`)

            })

            let current_number = data + 1
            let length = nanny_form.steps.length

            nanny_form.sidebar.html(`
                <div class="article-mc">
                    <div class="label">${label}</div>
                    <div class="add-company-progress"><span>${current_number > 9 ? current_number : '0' + current_number}</span>/${length > 9 ? length : '0' + length}</div>
                </div>

                <ul class="steps">${list.join(' ')}</ul>
            `)

            nanny_form.steps.removeClass('active')
            nanny_form.steps.eq(data).addClass('active')

            nanny_form.prev_btn.disable()

            if(data) {
                nanny_form.prev_btn.enable()
            }

            localStorage.setItem('nanny_current_step', data)

            return data
        },
        get: () => {
            return this.nanny_current_step
        }
    })

    nanny_form.prev = () => {
        let index = Math.max(nanny_form.step - 1, 0)

        nanny_form.step = index
    }

    nanny_form.next = () => {
        let step = nanny_form.steps.eq(nanny_form.step)
        let slug = step.data('slug')
        let errors = 0

        if(slug == 'general') {
            let inputs = step.find('.body').find('input:not(:disabled):not([name="nanny_microdistrict"]):not(#nanny_middle_name)')
            let image = $('#nanny_avatar')

            inputs.each(function(){
                let elem = $(this)

                errors += elem.check()
            })

            if(errors) {
                addNotif({
                    title: 'Ошибка',
                    text: 'Заполните все поля',
                    icon: 'error',
                    color: 'red',
                    timeout: 4000,
                })
            }

            if(!image[0].avatar) {
                errors++

                image.addClass('error')

                addNotif({
                    title: 'Ошибка',
                    text: 'Выберите изображения',
                    icon: 'error',
                    color: 'red',
                    timeout: 4000,
                })
            }

        } else if (slug == 'about') {
            let fields = step.find('#nanny_birth, #nanny_description')
            let local_errors = 0

            fields.each(function(){
                let elem = $(this)
                local_errors += elem.check()
            })

            if(local_errors) {
                addNotif({
                    title: 'Ошибка',
                    text: 'Заполните все поля',
                    icon: 'error',
                    color: 'red',
                    timeout: 4000,
                })
            }

            errors += local_errors
        } else if (slug == 'contacts') {
            let phone_input = $('#nanny_phone')
            let fields_to_check = [phone_input]
            let local_errors = 0

            for(let field of fields_to_check) {
                local_errors += field.check()
            }

            if(local_errors) {
                addNotif({
                    title: 'Ошибка',
                    text: 'Заполните все поля',
                    icon: 'error',
                    color: 'red',
                    timeout: 4000,
                })
            }

            errors += local_errors
        }

        if(!errors) {
            let length = nanny_form.steps.length
            if(nanny_form.step + 1 == length) {
                let register_form = $('.step[data-slug="register"]')

                if(register_form.length) {
                    register(send_nanny)
                } else {
                    send_nanny()
                }

                function send_nanny() {
                    nanny_form.update_data()

                    nanny_form.wrapper.start_loading()
                    nanny_form.wrapper.find('input, textarea, button').disable()

                    requestAjax('add_nanny', nanny_form.data, (res) => {
                        localStorage.removeItem('nanny_form');
                        localStorage.removeItem('nanny_current_step');

                        if(!window.debug) {
                            window.location.href = '/dashboard/'
                        }
                    })
                }
            } else {
                let index = Math.min(nanny_form.step + 1, nanny_form.steps.length - 1)
        
                nanny_form.step = index
                nanny_form.update_data()
            }
        } else {
            scroll_to_error()
        }

    }

    nanny_form.update_data = () => {
        nanny_form.data = {}

        nanny_form.steps.each(function(){
            let step = $(this)
            let slug = step.data('slug')

            if(slug == 'general') {
                let inputs = step.find('.body').find('input:not(:disabled)')
                let image = $('#nanny_avatar')[0].avatar
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    nanny_form.data[name] = elem.getValue()
                })
                
                nanny_form.data['image'] = image
            } else if (slug == 'about') {
                let filters = step.find('.filter-group')
                let select_filter = step.find('.select-filter')
                let write_filter = step.find('.write-filter')
                let description_field = step.find('#nanny_description')

                filters.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let values = []
                    let inputs = elem.find('input')

                    inputs.each(function(){
                        let item = $(this)
                        let value = item.data('slug')

                        if(item.prop('checked')) {
                            values.push(value)
                        }
                    })

                    nanny_form.data[name] = values
                })

                select_filter.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let input = elem.find('input')
                    let value = input[0].selected

                    nanny_form.data[name] = value
                })

                write_filter.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let input = elem.find('input')
                    let value = input.getValue()

                    nanny_form.data[name] = value
                })

                nanny_form.data['description'] = description_field.getValue()
            } else if(slug == 'contacts') {
                let inputs = step.find('input')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
                    let toggle = elem.siblings('.toggle.active')
                    
                    if(toggle.length) {
                        nanny_form.data['whatsapp_' + name] = elem.getValue()
                    }

                    nanny_form.data[name] = elem.getValue()
                })
            }
        })
    }

    nanny_form.prev_btn.click(nanny_form.prev)
    nanny_form.next_btn.click(nanny_form.next)

    let active = nanny_form.steps_wrapper.find('.step.active').index()
    let saved_active = localStorage.getItem('nanny_current_step')

    if(saved_active) {
        let max = $('[data-slug="gallery"]').index()
        saved_active = Math.min(saved_active, max)
        active = saved_active
    }

    nanny_form.step = Math.max(active, 0)

    nanny_form.steps.find('input, textarea').on('change location_selected selected', function(){
        let storage = JSON.parse(localStorage.getItem('nanny_form')) || {}
        let elem = $(this)
        let value = elem.getValue()
        let type = elem.data('type') || 'text'
        let id = elem.attr('id')

        if( type == 'checkbox' ) {
            value = elem.prop('checked') ? 1 : 0
        }

        storage[id] = {id, type, value}
        localStorage.setItem('nanny_form', JSON.stringify(storage))
    })

    $(document).ready(function () {
        let storage = JSON.parse(localStorage.getItem('nanny_form')) || ''
        
        if(storage) {
            for(let data of Object.values(storage)) {
                let {id, type, value} = data
                let input = $('#' + id)
    
                if(input.length) {
    
                    if(type == 'select') {
                        input[0].selected = value
                    } else if (type == 'text') {
                        input.val(value)
                    } else if (type == 'checkbox') {
                        input.prop('checked', value)
                    } else if (type == 'map') {
                        window.ymaps_data.onload.push(() => {
                            window.ymaps_data.search(value)
                        })
                    } else if (type == 'phone') {
                        input[0].mask.unmaskedValue = value
                    }
    
                }
            }
        }

        update_locations()
        nanny_form.update_data()
    })

} else if(nanny_form.edit_form.length) {
    nanny_form.steps_wrapper = $('.edit-nanny')
    nanny_form.steps = nanny_form.steps_wrapper.find('.step')
    nanny_form.data = {}
    nanny_form.wrapper = nanny_form.steps_wrapper.parents('.dashboard')
    nanny_form.submit = nanny_form.edit_form.find('#save_resume')

    nanny_form.update_data = () => {
        nanny_form.data = {}

        nanny_form.steps.each(function(){
            let step = $(this)
            let slug = step.data('slug')

            if(slug == 'general') {
                let inputs = step.find('.body').find('input:not(:disabled)')
                let image = $('#nanny_avatar')[0].avatar

                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
    
                    nanny_form.data[name] = elem.getValue()
                })
                
                nanny_form.data['image'] = image
            } else if (slug == 'about') {
                let filters = step.find('.filter-group')
                let select_filter = step.find('.select-filter')
                let write_filter = step.find('.write-filter')
                let description_field = step.find('#nanny_description')

                filters.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let values = []
                    let inputs = elem.find('input')

                    inputs.each(function(){
                        let item = $(this)
                        let value = item.data('slug')

                        if(item.prop('checked')) {
                            values.push(value)
                        }
                    })

                    nanny_form.data[name] = values
                })

                select_filter.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let input = elem.find('input')
                    let value = input[0].selected

                    nanny_form.data[name] = value
                })

                write_filter.each(function(){
                    let elem = $(this)
                    let name = elem.data('filter')
                    let input = elem.find('input')
                    let value = input.getValue()

                    nanny_form.data[name] = value
                })

                nanny_form.data['description'] = description_field.getValue()
            } else if(slug == 'contacts') {
                let inputs = step.find('input')
    
                inputs.each(function(){
                    let elem = $(this)
                    let name = elem.attr('name')
                    let toggle = elem.siblings('.toggle.active')
                    
                    if(toggle.length) {
                        nanny_form.data['whatsapp_' + name] = elem.getValue()
                    }

                    nanny_form.data[name] = elem.getValue()
                })
            }
        })

    }

    nanny_form.steps.find('input, textarea').on('change location_selected selected', function(){
        let storage = JSON.parse(localStorage.getItem('nanny_form')) || {}
        let elem = $(this)
        let value = elem.getValue()
        let type = elem.data('type') || 'text'
        let id = elem.attr('id')

        if( type == 'checkbox' ) {
            value = elem.prop('checked') ? 1 : 0
        }

        storage[id] = {id, type, value}
        localStorage.setItem('nanny_form', JSON.stringify(storage))
    })

    nanny_form.submit.on('click', () => {
        let errors = 0

        nanny_form.steps.each(function(){
            let step = $(this)
            let slug = step.data('slug')

            if(slug == 'general') {
                let inputs = step.find('.body').find('input:not(:disabled):not([name="nanny_microdistrict"])')
                let image = $('#nanny_avatar')
    
                inputs.each(function(){
                    let elem = $(this)
    
                    errors += elem.check()
                })
    
                if(!image[0].avatar) {
                    errors++
    
                    addNotif({
                        title: 'Ошибка',
                        text: 'Выберите изображения',
                        icon: 'error',
                        color: 'red',
                        timeout: 4000,
                    })
                }
    
            } else if (slug == 'about') {
                let fields = step.find('#nanny_birth, #nanny_description')
    
                fields.each(function(){
                    let elem = $(this)
                    errors += elem.check()
                })
    
            } else if (slug == 'contacts') {
                let phone_input = $('#nanny_phone')
                let fields_to_check = [phone_input]
    
                for(let field of fields_to_check) {
                    errors += field.check()
                }
    
            }

        })

        if(!errors) {
            nanny_form.update_data()
            nanny_form.wrapper.find('input, textarea, button').disable()

            // ОТПРАВКА
            requestAjax('edit_nanny', {
                id: window.post_id,
                data: nanny_form.data,
            }, (res) => {

                if(!window.debug) {
                    addNotif({
                        title: 'Сохранено',
                        text: 'Изменения были успешно сохранены',
                        icon: 'success',
                        color: 'green',
                        timeout: 4000,
                    })

                    setTimeout(() => {
                        window.location.reload()
                    }, 4000)
                }
            })

        }
    })

    $(document).ready(function () {
        update_locations()
        nanny_form.update_data()
    })
}
$(document).on('click', '.anchor', function(){
    let elem = $(this)
    let target = $('#' + elem.data('elem'))

    if(target.length) {
        let destination = target.offset().top - $('header.site-header').height()
        jQuery("html,body").animate({
          scrollTop: destination
        }, 500)
    }
})
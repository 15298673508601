function displayAuthErrors(fields){
    $.each(fields, (id, value) => {
        $('#' + id).addClass('error')

        addNotif({
            title: 'Ошибка',
            text: value,
            icon: 'close',
            color: 'red',
            timeout: 4000
        })

    })
}

function login(e){
    let form = $('.auth-form')
    let loginField = $('#login_phone_or_email')
    let passwordField = $('#login_password')
    let rememberField = $('#login_remember')
    let btn = $('#login_send')
    let errors = 0
    let checkFields = [loginField, passwordField]

    e.preventDefault()

    for(field of checkFields){
        errors += field.check()
    }

    if(!errors){
        let login = loginField.getValue()
        let password = passwordField.getValue()
        let remember = rememberField.prop('checked') ? 1 : 0
        let data = {login, password, remember}

        btn.disable(true)

        requestAjax('login', data, res => {
            res = JSON.parse(res)

            if(res.res == 'error'){
                displayAuthErrors(res.fields)
                btn.enable()
            }else{
                window.location.href = '/dashboard'
            }
        })
    }

}

$(document).on('click', '#login_send', login)